@keyframes fade-out-blur {
  0% {
    opacity: 1;
    filter: blur(0);
  }
  25% {
    filter: blur(3px);
  }
  75% {
    filter: blur(5px);
  }
  100% {
    opacity: 0;
  }
}

.locl-home-main {
  padding-top: 100px;
  overflow: hidden;

  &__title {
    font-family: var(--secondary-font-family);
    font-weight: 700;
    font-size: 50px;
    width: fit-content;
    line-height: 53px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 60px;
    color: var(--dark-color-500);
  }

  &__looking-for {
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1800px;
    margin: auto;
    margin-bottom: 80px;

    &__single-item {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 540px;
      margin: 0 auto;
      padding: 0 12px;

      &__title {
        font-family: var(--secondary-font-family);
        color: var(--dark-color-500);
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 1;
        margin-bottom: 24px;
        width: 100%;
      }

      &__button-row {
        display: flex;
      }
      &__button-icon {
        display: flex !important;
        align-items: center;
        justify-content: center;
        max-height: 10px;
        max-width: 18px;

        svg {
          width: 100%;
          height: 100%;
          vertical-align: unset;
        }
      }

      &__text {
        font-family: var(--secondary-font-family);
        font-weight: 400;
        color: var(--dark-color-300);
        text-align: center;
        font-size: 18px;
        line-height: 160%;
        width: 500px;
        margin-bottom: 24px;
      }

      .locl-home-main__button {
        margin-bottom: 44px;

        &_type-secondary {
          margin-left: 20px;
        }
      }
    }
  }

  &__location-events {
    display: flex;
    padding: 0 8vw;
    justify-content: space-between;

    &__single-item {
      width: 540px;

      &__title {
        font-family: var(--secondary-font-family);
        text-align: center;
        font-size: 24px;
        color: var(--dark-color-500);
        font-weight: 700;
        margin-bottom: 32px;
        min-width: 230px;
      }
    }

    &__left {
      margin-left: -20px;
    }
  }

  &__divider {
    background: var(--secondary-color-300);
    width: 1px;
    min-height: 100%;
  }

  &__carousel {
    height: 320px;
    margin-bottom: 32px;

    .locl-home-main__swiper-img {
      width: 100%;
      height: 320px;
      object-fit: cover;
    }
  }

  &__swiper-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__button {
    margin: 0 auto;
    font-size: 18px;
    font-weight: 500;
    border-radius: 4px;
    padding: 14px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color-500);
    border: none;
    box-shadow: none;
    outline: none;
    transition: all 0.3s;

    &_label {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      transition: 0.5s;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:not(:first-child) {
        margin-left: 10px;
      }
    }

    @media (hover: hover) {
      &:hover {
        outline: none;

        .locl-home-main__button_label {
          &:not(:last-child) {
            margin-right: 20px;
          }

          &:not(:first-child) {
            margin-left: 20px;
          }
        }
      }
    }

    &_color {
      &-red {
        background: var(--danger-color-500);

        @media (hover: hover) {
          &:hover {
            background: var(--primary-color-700);
            color: var(--white-color-500);
          }
        }
      }

      &-green {
        background: var(--primary-color-700);

        @media (hover: hover) {
          &:hover {
            background: var(--danger-color-500);
            color: var(--white-color-500);
          }
        }
      }
    }

    &_type {
      &-secondary {
        background: var(--white-color-500);
        color: var(--danger-color-500);
        border: 1px solid var(--danger-color-500);

        @media (hover: hover) {
          &:hover {
            background: var(--white-color-500);
          }
        }

        &.locl-home-main__button_color-green {
          color: var(--primary-color-700);
          border: 1px solid var(--primary-color-700);
        }
      }
    }
  }

  :global {
    .swiper-slide {
      width: 100% !important;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-pagination-bullet {
      height: 8px;
      width: 8px;
      background: var(--white-color-500);
      opacity: 0.4;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: var(--white-color-500);
    }
  }
}

.locations-events {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 180px);
  grid-template-rows: repeat(2, 180px);
  justify-content: center;

  &__link {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background: none;
    margin-bottom: 0;
    width: 180px;

    &:hover {
      .locations-events__img {
        transform: scale(1.1);
      }
      .locations-events__caption {
        animation: 0.3s forwards ease-in-out fade-out-blur;
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    max-width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
    z-index: -1;
    transition: 0.3s;
    will-change: transform;
  }

  &__icon {
    display: block;
    position: static;
    height: 24px;
    width: 24px;
    margin-bottom: 16px;
  }

  &__caption {
    padding: 12px 18px;
    background: rgba(#000000, 0.5);
    height: 70px;
    display: flex;
    align-items: center;
    text-align: center;

    &__text {
      font-family: var(--secondary-font-family);
      color: var(--white-color-500);
      font-weight: 700;
      line-height: 25px;
      font-size: 17px;
      margin-bottom: 0;
      width: auto;
      height: auto;
    }
  }
}

@media screen and (max-width: 1280px) {
  .locl-home-main {
    &__button {
      padding: 14px 14px;
    }

    &__looking-for {
      &__single-item {
        &__text {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .locations-events {
    grid-template-columns: repeat(2, 180px);
    grid-template-rows: repeat(3, 180px);

    &__single-item {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1050px) {
  .locl-home-main {
    &__location-events {
      &__single-item {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 870px) {
  .locl-home-main {
    padding-top: 32px;
    margin-bottom: 0;

    &__title {
      font-size: 30px;
      margin-bottom: 16px;
      line-height: 44px;
    }

    &__divider {
      display: none;
    }

    &__button {
      padding: 14px 0;
      width: 100%;
      max-width: 300px;
      white-space: nowrap;
      text-align: center;
    }

    &__carousel {
      height: 140px;
      width: 100%;

      .locl-home-main__swiper-img {
        width: 100%;
        object-fit: cover;
        height: 140px;
        margin-bottom: 12px;
        filter: brightness(80%);
      }
    }

    &__looking-for {
      flex-direction: column;
      padding: 0 12px;
      margin-bottom: 0;

      &__single-item {
        width: 100%;
        padding: 0;

        &__title {
          position: absolute;
          left: 0;
          color: var(--white-color-500);
          top: 48px;
          z-index: 1;
        }

        &__text {
          color: var(--dark-color-500);
          font-size: 16px;
        }

        &__button-row {
          width: 100%;
        }

        .locations-events {
          margin-bottom: 24px;

          &__link {
            background: var(--primary-color-100);

            &:hover {
              background: var(--gray-color-300);
            }
          }
        }
      }
    }

    &__location-events {
      &__left {
        margin-left: 0;

        .locations-events {
          &__link {
            background: rgba(255, 223, 223, 0.5);

            &:hover {
              background: var(--gray-color-300);
            }
          }
        }
      }

      &__single-item {
        &__title {
          white-space: normal;
          font-size: 24px;
          min-width: 0;
          line-height: 24px;
        }
      }
    }
  }

  .locations-events {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 102px);
    column-gap: 5px;
    row-gap: 5px;
    margin-left: 0;
    padding: 0 2px;
    margin-bottom: 32px;

    &__link {
      width: 100%;
      padding: 0;

      &:hover {
        .locations-events__caption {
          animation: none;
        }
      }
    }

    &__img {
      display: none;
    }

    &__caption {
      background: none !important;
      flex-direction: column;

      &__text {
        font-size: 12px;
        padding: 0;
        line-height: 16px;
        font-weight: 400;
        color: #6b7280;
      }
    }
  }
}
